import React, {useState} from 'react'
import styled from 'styled-components'
import { device } from '../models/device'
import { theme } from '../models/theme'
import { RegularGapContentDiv, FullPageHeightContentSection, GradientCoverTopBottom, ContentSection3D, StyledPageDiv, StyledSpacerDiv, StyleSlide, HorizontalFeatureSection, CoverImage, ContentSection, FullPageWidthContentSection } from '../general/GeneralStyles'
import HorizontalPreviewSlider from '../components/HorizontalPreviewSlider'
import HorizontalAspectRatioSlide from '../components/HorizontalAspectRatioSlide'
import HorizontalTeaserSlide from '../components/HorizontalTeaserSlide'
import UnderlineRadio from '../components/UnderlineRadio'
import AnimatedTypedText from '../components/AnimatedTypedText'

import placeholder1 from '../images/landing/placeholder_lipsync.png';
import placeholder2 from '../images/landing/placeholder_faceswap.png';

import hero1 from '../images/services/AIVFXServices_1.jpg';
import hero2 from '../images/services/AIVFXServices_2b.jpg';
import hero3 from '../images/services/AIVFXServices_3.jpg';

import { ReactComponent as Arrow } from "../images/services/arrow.svg";
import { ReactComponent as SplitArrow } from "../images/services/split_arrow.svg";
import {ReactComponent as WavePlaceholder} from "../images/services/waveform.svg";
import {ReactComponent as Wave2Placeholder} from "../images/services/waveform2.svg";
import {ReactComponent as Wave3Placeholder} from "../images/services/waveform3.svg";

import PillButton from '../components/PillButton'
import AudioWavePlayer from '../components/AudioWavePlayer'

import test_audio from '../audio/services/hello.wav'

import chart1driver from '../images/services/01_AILipsync/AILipsync_driver.jpg'
import chart1source from '../images/services/01_AILipsync/AILipsync_source.jpg'
import chart1result from '../images/services/01_AILipsync/AILipsync_lipMovement.jpg'

import chart2driver from '../images/services/02_FaceReenactment/faceReenactment_driver.jpg'
import chart2source from '../images/services/02_FaceReenactment/faceReenactment_source.jpg'
import chart2result from '../images/services/02_FaceReenactment/faceReenactment_reenactment.jpg'

import chart3target from '../images/services/03_AIFaceSwap/aiFaceSwap_inputTarget.jpg'
import chart3source from '../images/services/03_AIFaceSwap/aiFaceSwap_inputSource.jpg'
import chart3result from '../images/services/03_AIFaceSwap/aiFaceSwap_outputFaceSwap.jpg'

import chart4target from '../images/services/04_DeAge/DeAge_inputTargetAge.jpg'
import chart4source from '../images/services/04_DeAge/DeAge_inputSource.jpg'
import chart4result from '../images/services/04_DeAge/DeAge_outputDeAge.jpg'

import chart5target from '../images/services/05_LiveFaceSwap/LiveFaceSwap_inputTarget.jpg'
import chart5source from '../images/services/05_LiveFaceSwap/liveFaceSwap_inputSource.jpg'
import chart5result from '../images/services/05_LiveFaceSwap/liveFaceSwap_outputFaceSwap.jpg'
import { Helmet } from 'react-helmet-async'

export const LeftTextRegion = styled.div`
    width: 30%;
    height: auto;

    p {
        text-align: left;
    }

    @media ${device.mobile} {
        width: 90%;
            p {
                text-align: center;
            }
    }
`

export const SlideContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 80%;
    height: 100%;

    p {
        justify-self: flex-end;
    }
`
export const DescriptionContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
`

export const Description = styled.p`
justify-self: flex-end;
    width: 80%;
    margin-block: 0;
    margin-bottom: 4rem;
    @media ${device.mobile} {
        margin-bottom: 3rem;
    }
`

export const RadioSection = styled.div`
    width: 100%;
    height: auto;
`

export const InnerContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;

    p {
        margin-block-end: 0rem;
        margin-block-end: 2rem;
        margin-block: 0;
    }
`

export const SlideImg = styled.img`
    object-fit: cover;
    object-position: center;
    aspect-ratio: ${props => props.aspectRatio};
    height: auto;
    width: 90%;
    margin-block: 1rem;
`

export const SlideDiagram = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    aspect-ratio: ${props => props.aspectRatio};
    height: auto;
    width: 100%;
    max-width: 90%;
    margin-block: 1rem;
    @media ${device.mobile} {
        aspect-ratio: unset;
        height: auto;
        max-width: 100%;
    }
`

export const ButtonDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`

export const LeftP = styled.p`
    text-align: left;
`

export const StyledArrow = styled(Arrow)`
    display: flex;
    flex-direction: row;
    flex-shrink: ${props => props.flexShrink ? props.flexShrink : '1'};
    height: auto;
    width: ${props => props.iconWidth};
    min-width: ${props => props.iconWidth};
    fill: ${props => props.theme.accentVariant };
    margin: 0.5rem;
    aspect-ratio: 1;
    @media ${device.mobile} {
        height: 4rem;
        width: auto;
        transform: rotate(90deg);
    }
`

export const StyledSplitArrow = styled(SplitArrow)`
    display: flex;
    flex-direction: row;
    flex-shrink: ${props => props.flexShrink ? props.flexShrink : '1'};    height: auto;
    width: ${props => props.iconWidth};
    height: auto;
    fill: ${props => props.theme.accentVariant };
    stroke: ${props => props.theme.accentVariant };
    margin: 0.5rem;
    aspect-ratio: 0.5;
    @media ${device.mobile} {
        flex-direction: column;
        height: ${props => props.iconWidth};
        width: auto;
        aspect-ratio: 2;
        transform: rotate(90deg);
    }
`

export const StyledWave = styled(WavePlaceholder)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-shrink: ${props => props.flexShrink ? props.flexShrink : '1'};
    height: auto;
    width: ${props => props.iconWidth};
    aspect-ratio: 16/9;
    filter: drop-shadow(0px 0px 3rem ${props => props.theme.accent });
`

export const StyledWaveTwo = styled(Wave2Placeholder)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-shrink: ${props => props.flexShrink ? props.flexShrink : '1'};
    height: auto;
    width: ${props => props.iconWidth};
    aspect-ratio: 16/9;
    filter: drop-shadow(0px 0px 3rem ${props => props.theme.accent });
`

export const StyledWaveThree = styled(Wave3Placeholder)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-shrink: ${props => props.flexShrink ? props.flexShrink : '1'};
    height: auto;
    width: ${props => props.iconWidth};
    aspect-ratio: 16/9;
    filter: drop-shadow(0px 0px 3rem ${props => props.theme.accent });
`

export const VerticalContentBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: ${props => props.flexShrink ? props.flexShrink : '1'};
    justify-content: ${props => props.setJustifyContent ? props.setJustifyContent : 'space-between'};
    align-items: ${props => props.setAlignItems ? props.setAlignItems : 'center'};
    aspect-ratio: ${props => props.aspectRatio ? props.aspectRatio : 'unset'};
    width: ${props => props.setWidth ? props.setWidth : '100%'};
    height: ${props => props.setHeight ? props.setHeight : '100%'};
    background: none;
    margin: 0.5rem;
    padding: 0.5rem;
    gap: 0.5rem;

    overflow: visible;

    @media ${device.mobile} {
        flex-direction: ${props => props.noDirectionSwap ? 'column' : 'row'};
        aspect-ratio: ${props => props.aspectRatio ? (1/props.aspectRatio) : 'unset'};
        width: ${props => props.setHeight ? props.setHeight : '100%'};
        height: ${props => props.setWidth ? props.setWidth : '100%'};
    }
`

export const HorizontalContentBox = styled.div`
    display: flex;
    flex-direction: row;
    flex-shrink: ${props => props.flexShrink ? props.flexShrink : '1'};
    justify-content: ${props => props.setJustifyContent ? props.setJustifyContent : 'space-between'};
    align-items: ${props => props.setAlignItems ? props.setAlignItems : 'center'};
    align-self: ${props => props.setAlignSelf ? props.setAlignSelf : 'unset'};
    aspect-ratio: ${props => props.aspectRatio ? props.aspectRatio : 'unset'};
    width: ${props => props.setWidth ? props.setWidth : '100%'};
    height: ${props => props.setHeight ? props.setHeight : '100%'};
    background: none;
    margin: 0.5rem;
    padding: 0.5rem;
    gap: 0.5rem;
    @media ${device.mobile} {
        flex-direction: ${props => props.noDirectionSwap ? 'row' : 'column'};
        aspect-ratio: ${props => props.aspectRatio ? (1/props.aspectRatio) : 'unset'};
        width: ${props => props.setHeight ? props.setHeight : '100%'};
        height: ${props => props.setWidth ? props.setWidth : '100%'};
    }
`

export const GreyBox = styled.div`
    text-align: left;
    display: flex;
    flex-direction: column;
    flex-shrink: ${props => props.flexShrink ? props.flexShrink : '1'};
    justify-content: flex-end;
    align-items: start;
    aspect-ratio: ${props => props.aspectRatio};
    width: ${props => props.setWidth ? props.setWidth : '100%'};
    height: ${props => props.setHeight ? props.setHeight : '100%'};
    border-radius: 1rem;
    background: url(${props => props.background}) no-repeat center;
    background-size: cover;
    padding: 0.5rem;
    margin: 0.5rem;

    p {
        text-shadow: black 1px 1px 2px;
    }

    @media ${device.mobile} {
        p {
            font-size: 0.75rem;
        }
        aspect-ratio: ${props => props.aspectRatio ? (1/props.aspectRatio) : 'unset'};
        width: ${props => props.setHeight ? props.setHeight : '100%'};
        height: ${props => props.setWidth ? props.setWidth : '100%'};
    }
`

export const GreenShadowBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: ${props => props.flexShrink ? props.flexShrink : '1'};
    justify-content: center;
    align-items: center;
    aspect-ratio: ${props => props.aspectRatio};
    width: ${props => props.setWidth ? props.setWidth : '100%'};
    height: ${props => props.setHeight ? props.setHeight : '100%'};
    border-radius: 1rem;
    background: ${props => props.theme.accentVariant };
    box-shadow: 1rem -1rem ${props => props.theme.accentVariantDark };
    padding: 0.5rem;
    margin: 0.5rem;
    @media ${device.mobile} {
        aspect-ratio: ${props => props.aspectRatio ? (1/props.aspectRatio) : 'unset'};
        width: ${props => props.setHeight ? props.setHeight : '100%'};
        height: ${props => props.setWidth ? props.setWidth : '100%'};
    }
`

export default function VFXServices() {
    const [slideOneIndex, setSlideOneIndex] = useState(0);
    const [slideTwoIndex, setSlideTwoIndex] = useState(0);
    const [slideThreeIndex, setSlideThreeIndex] = useState(0);

    return(
        <StyledPageDiv>
            <Helmet>
                <title>AI VFX Services</title>
                <meta name="description" content="We focus on AI VFX services for high resolution and photorealistic digital actors, ranging from face reenactment, AI lipsync, face swaps and de-aging, as well as voice cloning." key="desc" />
                <meta property="og:title" content="AI VFX Services" />
                <meta
                property="og:description"
                content="We focus on AI VFX services for high resolution and photorealistic digital actors, ranging from face reenactment, AI lipsync, face swaps and de-aging, as well as voice cloning."
                />
            </Helmet>
            <StyledSpacerDiv/>
            <RegularGapContentDiv perspective='1px'>
                <FullPageWidthContentSection transform='translateZ(0px)'>
                    <h1>
                        AI VFX Services
                    </h1>
                    <HorizontalPreviewSlider  setHeight={'calc( min(100vh - 86px - 5rem , 165vw))'} autoPlayOn>
                        <StyleSlide>
                            <HorizontalTeaserSlide source={hero1}>
                                <GradientCoverTopBottom>
                                    <DescriptionContainer>
                                        <Description>
                                            We are the world’s first company to provide an integrated approach to state-of-the-art generative AI for film and TV production. We develop cutting-edge technologies for our VFX pipeline and continuously explore new use cases with some of the greatest film makers and studios.
                                        </Description>
                                    </DescriptionContainer>
                                </GradientCoverTopBottom>
                            </HorizontalTeaserSlide>
                        </StyleSlide>
                        <StyleSlide>
                            <HorizontalTeaserSlide source={hero2}>
                            <GradientCoverTopBottom>
                                    <DescriptionContainer>
                                <Description>
                                    We focus on AI VFX services for high resolution and photorealistic digital actors, ranging from face reenactment, AI lipsync, face swaps and de-aging, as well as voice cloning.
                                </Description>
                                </DescriptionContainer>
                                </GradientCoverTopBottom>
                            </HorizontalTeaserSlide>
                        </StyleSlide>
                        <StyleSlide>
                            <HorizontalTeaserSlide source={hero3}>
                            <GradientCoverTopBottom>
                                    <DescriptionContainer>
                                <Description>
                                    Our unique approach offers the highest visual standard in film production. Our process is art-directable, scalable, costs less, and uses non-intrusive data capture that's respectful of actors and creative directors time. We can even handle any film material that is already shot.
                                </Description>
                                </DescriptionContainer>
                                </GradientCoverTopBottom>
                            </HorizontalTeaserSlide>
                        </StyleSlide>
                    </HorizontalPreviewSlider>
                </FullPageWidthContentSection>
                <ContentSection3D transform='translateZ(-1px) scale(2))'>
                    <UnderlineRadio navigationArrows
                    optionList={[{text: 'AI Lip Sync', tag: 0}, {text: 'Face Reenactment', tag: 1}]}
                    handleSubmit={ (tag) => {
                        setSlideOneIndex(tag);
                    } }
                    >
                        <InnerContent>
                            {
                                slideOneIndex == 0 ?
                                <>
                                    <SlideDiagram aspectRatio={'30/9'}>
                                        <HorizontalContentBox>
                                            <VerticalContentBox setAlignItems='end'>
                                                <HorizontalContentBox setHeight='40%'>
                                                    <GreyBox setWidth='auto' aspectRatio='1' background={chart1driver}>
                                                        <p>driver (mouth)</p>
                                                    </GreyBox>
                                                    <VerticalContentBox setWidth='auto' aspectRatio='1' noDirectionSwap>
                                                        <StyledWave iconWidth='100%'/>
                                                        <p>audio (optional)</p>
                                                    </VerticalContentBox>
                                                    <StyledArrow iconWidth='4rem'/>
                                                    <GreenShadowBox setWidth='auto' aspectRatio='1'>
                                                        <p>lip movement</p>
                                                    </GreenShadowBox>
                                                </HorizontalContentBox>
                                                <HorizontalContentBox setHeight='40%' >
                                                    <GreyBox setWidth='auto' aspectRatio='1' background={chart1source}>
                                                        <p>source</p>
                                                    </GreyBox>
                                                    <StyledArrow iconWidth='4rem'/>
                                                    <GreenShadowBox setWidth='auto' aspectRatio='1'>
                                                        <p>appearance head pose</p>
                                                    </GreenShadowBox>
                                                </HorizontalContentBox>
                                            </VerticalContentBox>
                                            <StyledSplitArrow iconWidth={'100%'} flexShrink='2.4'/>
                                            <GreyBox aspectRatio='1' setWidth='auto' setHeight='40%' background={chart1result}>
                                                <p>lip movement</p>
                                            </GreyBox>
                                        </HorizontalContentBox>
                                    </SlideDiagram>
                                    <LeftP>
                                        The process of <a>AI lip sync</a> and uses the mouth area of the <a>driver video</a> to puppeteer the facial performance of a <a>source video</a> footage.
                                        The actors for both driver video and source video can be the same or different people, and we only need a single video recording.
                                        No extra training data is required, and the source video clip can be shorter than a second.
                                        Our technology can use either the video or the audio as input for the reenactment.
                                    </LeftP>
                                    {/* <ButtonDiv>
                                        <PillButton baseBackground={theme.button} baseColor={theme.textLight} hoverBackground={theme.buttonHover} hoverColor={theme.textLight} value={"Example"}/>
                                    </ButtonDiv> */}
                                </>
                                :
                                <>
                                    <SlideDiagram aspectRatio={'30/9'}>
                                        <HorizontalContentBox>
                                            <VerticalContentBox flexShrink='2'>
                                            <HorizontalContentBox setHeight='40%'>
                                                    <GreyBox setWidth='auto' aspectRatio='1' background={chart2driver}>
                                                        <p>driver (face)</p>
                                                    </GreyBox>
                                                    <StyledArrow iconWidth='4rem'/>
                                                    <GreenShadowBox setWidth='auto' aspectRatio='1'>
                                                        <p>expressions</p>
                                                    </GreenShadowBox>
                                                </HorizontalContentBox>
                                                <HorizontalContentBox setHeight='40%'>
                                                    <GreyBox setWidth='auto' aspectRatio='1' background={chart2source}>
                                                        <p>source</p>
                                                    </GreyBox>
                                                    <StyledArrow iconWidth='4rem'/>
                                                    <GreenShadowBox setWidth='auto' aspectRatio='1'>
                                                        <p>appearance head pose</p>
                                                    </GreenShadowBox>
                                                </HorizontalContentBox>
                                            </VerticalContentBox>
                                            <StyledSplitArrow iconWidth={'40%'} flexShrink='2'/>
                                            <GreyBox setWidth='auto' aspectRatio='1' setHeight='40%' background={chart2result}>
                                                <p>face reenactment</p>
                                            </GreyBox>
                                        </HorizontalContentBox>
                                    </SlideDiagram>
                                    <LeftP>
                                        The process of <a>face reenactment</a> uses the full face of the <a>driver video</a> to puppeteer the facial performance of a <a>source video</a> footage.
                                        The actors for both driver video and source video can be the same or different people, and we only need a single video recording.
                                        No extra training data is required, and the source video clip can be shorter than a second.
                                    </LeftP>
                                    {/* <ButtonDiv>
                                        <PillButton baseBackground={theme.button} baseColor={theme.textLight} hoverBackground={theme.buttonHover} hoverColor={theme.textLight} value={"Example"}/>
                                    </ButtonDiv> */}
                                </>
                            }
                        </InnerContent>
                    </UnderlineRadio>
                    <UnderlineRadio 
                    optionList={[ {text: 'AI Face Swap', tag: 0}, {text: 'De-Aging', tag: 1}]}
                    handleSubmit={ (tag) => {
                        setSlideTwoIndex(tag);
                    } }>
                        <InnerContent>
                            {
                        slideTwoIndex == 0 ?
                                <>
                                    <SlideDiagram aspectRatio={'30/9'}>
                                        <HorizontalContentBox>
                                            <VerticalContentBox flexShrink='2'>
                                            <HorizontalContentBox setHeight='40%'>
                                                    <GreyBox setWidth='auto' aspectRatio='1' background={chart3target}>
                                                        <p>target face</p>
                                                    </GreyBox>
                                                    <StyledArrow iconWidth='4rem'/>
                                                    <GreenShadowBox setWidth='auto' aspectRatio='1'>
                                                        <p>appearance</p>
                                                    </GreenShadowBox>
                                                </HorizontalContentBox>
                                                <HorizontalContentBox setHeight='40%'>
                                                    <GreyBox setWidth='auto' aspectRatio='1' background={chart3source}>
                                                        <p>source</p>
                                                    </GreyBox>
                                                    <StyledArrow iconWidth='4rem'/>
                                                    <GreenShadowBox setWidth='auto' aspectRatio='1'>
                                                        <p>expressions</p>
                                                        <p>head pose</p>
                                                        <p>lighting</p>                                                    </GreenShadowBox>
                                                </HorizontalContentBox>
                                            </VerticalContentBox>
                                            <StyledSplitArrow iconWidth={'40%'} flexShrink='2'/>
                                            <GreyBox setWidth='auto' aspectRatio='1' setHeight='40%' background={chart3result}>
                                                <p>AI face swap</p>
                                            </GreyBox>
                                        </HorizontalContentBox>
                                    </SlideDiagram>
                                    <LeftP>
                                        The process of AI face swap is to replace the face of an actor in a source video footage with a target subject, which is another person.
                                        The target subject can be a single photograph or a large collection of videos capturing its range of expressions.
                                    </LeftP>
                                    {/* <ButtonDiv>
                                        <PillButton baseBackground={theme.button} baseColor={theme.textLight} hoverBackground={theme.buttonHover} hoverColor={theme.textLight} value={"Example"}/>
                                    </ButtonDiv> */}
                                </>
                                :
                                <>
                                    <SlideDiagram aspectRatio={'30/9'}>
                                        <HorizontalContentBox>
                                            <VerticalContentBox flexShrink='2'>
                                            <HorizontalContentBox setHeight='40%'>
                                                    <GreyBox setWidth='auto' aspectRatio='1' background={chart4target}>
                                                        <p>target face</p>
                                                    </GreyBox>
                                                    <StyledArrow iconWidth='4rem'/>
                                                    <GreenShadowBox setWidth='auto' aspectRatio='1'>
                                                        <p>appearance</p>
                                                    </GreenShadowBox>
                                                </HorizontalContentBox>
                                                <HorizontalContentBox setHeight='40%'>
                                                    <GreyBox setWidth='auto' aspectRatio='1' background={chart4source}>
                                                        <p>source</p>
                                                    </GreyBox>
                                                    <StyledArrow iconWidth='4rem'/>
                                                    <GreenShadowBox setWidth='auto' aspectRatio='1'>
                                                        <p>expressions</p>
                                                        <p>head pose</p>
                                                        <p>lighting</p>                                                    </GreenShadowBox>
                                                </HorizontalContentBox>
                                            </VerticalContentBox>
                                            <StyledSplitArrow iconWidth={'40%'} flexShrink='2'/>
                                            <GreyBox setWidth='auto' aspectRatio='1' setHeight='40%' background={chart4result}>
                                                <p>de-aging</p>
                                            </GreyBox>
                                        </HorizontalContentBox>
                                    </SlideDiagram>               
                                    <LeftP>
                                        The process of de-aging is to replace the face of an actor in a source video footage with its younger self’s face (target).
                                        The target face can be a single photograph or a large collection of videos with the desired age range.
                                        We also offer the possibility of facial aging allowing our clients to guide the desired age and look.
                                    </LeftP>
                                    {/* <ButtonDiv>
                                        <PillButton baseBackground={theme.button} baseColor={theme.textLight} hoverBackground={theme.buttonHover} hoverColor={theme.textLight} value={"Example"}/>
                                    </ButtonDiv> */}
                                </>
                            }
                        </InnerContent>
                    </UnderlineRadio>
                    <UnderlineRadio 
                    optionList={[{text: 'Live Face Swap', tag: 0}, {text: 'Voice Cloning', tag: 1}]}
                    handleSubmit={ (tag) => {
                        setSlideThreeIndex(tag);
                    } }>
                        <InnerContent>
                            { 
                            slideThreeIndex == 0 ?
                            <>
                            <SlideDiagram aspectRatio={'30/9'}>
                                <HorizontalContentBox>
                                    <VerticalContentBox flexShrink='2'>
                                    <HorizontalContentBox setHeight='40%'>
                                            <GreyBox setWidth='auto' aspectRatio='1' background={chart5target}>
                                                <p>target subject (pre-computed)</p>
                                            </GreyBox>
                                            <StyledArrow iconWidth='4rem'/>
                                            <GreenShadowBox setWidth='auto' aspectRatio='1'>
                                                <p>appearance</p>
                                            </GreenShadowBox>
                                        </HorizontalContentBox>
                                        <HorizontalContentBox setHeight='40%'>
                                            <GreyBox setWidth='auto' aspectRatio='1' background={chart5source}>
                                                <p>source performer (30 fps)</p>
                                            </GreyBox>
                                            <StyledArrow iconWidth='4rem'/>
                                            <GreenShadowBox setWidth='auto' aspectRatio='1'>
                                                <p>expressions</p>
                                                <p>head pose</p>
                                                <p>lighting</p>                                                    </GreenShadowBox>
                                        </HorizontalContentBox>
                                    </VerticalContentBox>
                                    <StyledSplitArrow iconWidth={'40%'} flexShrink='2'/>
                                    <GreyBox setWidth='auto' aspectRatio='1' setHeight='40%' background={chart5result}>
                                        <p>live face swap (30 fps)</p>
                                    </GreyBox>
                                </HorizontalContentBox>
                            </SlideDiagram> 
                            <LeftP>
                                Our live face swap Technology can instantly replace a source performer in front of a camera with a target subject and process in real-time (30 fps)
                                No extra training is required for the source person, and for the target subject, we can either use a single photo or a data set of videos.
                                This high-fidelity real-time solution is suitable for on set preview or for live TV productions and works for challenging lighting conditions.
                            </LeftP>
                            {/* <ButtonDiv>
                                <PillButton baseBackground={theme.button} baseColor={theme.textLight} hoverBackground={theme.buttonHover} hoverColor={theme.textLight} value={"Example"}/>
                            </ButtonDiv> */}
                            </>
                            :
                            <>
                            <SlideDiagram aspectRatio={'30/9'}>
                                <HorizontalContentBox setJustifyContent='space-between'>
                                    <VerticalContentBox setJustifyContent='center' setWidth='auto' setAlignItems='center'>
                                        <VerticalContentBox setWidth='auto' setHeight='40%' setJustifyContent='center' aspectRatio='1' noDirectionSwap>
                                            <StyledWave iconWidth='100%'/>
                                            <p>target voice samples</p>
                                        </VerticalContentBox>
                                        <VerticalContentBox setWidth='auto' setHeight='40%' aspectRatio='1' setJustifyContent='center' noDirectionSwap>
                                            <StyledWaveTwo iconWidth='100%'/>
                                            <p>source speech</p>
                                        </VerticalContentBox>
                                    </VerticalContentBox>
                                    <VerticalContentBox setJustifyContent='center' setWidth='auto' setAlignItems='center'>
                                        <VerticalContentBox setAlignItems='center' setWidth='auto' setHeight='100%' setJustifyContent='center' aspectRatio='1' noDirectionSwap>
                                            <StyledArrow iconWidth='4rem'/>
                                        </VerticalContentBox>
                                        <VerticalContentBox setAlignItems='center' setWidth='auto' aspectRatio='1' setJustifyContent='center' noDirectionSwap>
                                            <StyledArrow iconWidth='4rem'/>
                                        </VerticalContentBox>
                                    </VerticalContentBox>
                                    <VerticalContentBox setJustifyContent='flex-end' setWidth='auto' setAlignItems='end'>
                                        <GreenShadowBox setWidth='auto' aspectRatio='1'>
                                            <p>voice ID</p>
                                        </GreenShadowBox>
                                        <GreenShadowBox setWidth='auto' aspectRatio='1'>
                                            <p>speech</p>
                                        </GreenShadowBox>
                                    </VerticalContentBox>
                                    <StyledSplitArrow iconWidth={'14rem'}/>
                                    <VerticalContentBox setHeight='40%' setWidth='auto' setJustifyContent='center' aspectRatio='1' noDirectionSwap>
                                        <StyledWaveThree iconWidth='100%'/>
                                        <p>retargeted speech</p>
                                    </VerticalContentBox>
                                </HorizontalContentBox>
                            </SlideDiagram>                    
                            <LeftP>
                                Voice cloning produces a retargeted speech by replacing the voice of a source actor with the one of a target person.
                                We only need a few minutes of clean voice recording of the target subject as training data for the processing.
                                Our technology faithfully reproduces the conversation of the source with the voice of the target person.
                            </LeftP>
                            {/* <ButtonDiv>
                                <PillButton baseBackground={theme.button} baseColor={theme.textLight} hoverBackground={theme.buttonHover} hoverColor={theme.textLight} value={"Example"}/>
                            </ButtonDiv> */}
                            </>
                            }
                        </InnerContent>
                    </UnderlineRadio>
                </ContentSection3D>
            </RegularGapContentDiv>
        </StyledPageDiv>
    )
}