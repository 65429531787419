import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { SwiperSlide } from "swiper/react";
import { device } from '../models/device';
import MinimalVideo from "./MinimalVideo";

export const StyleSlide = styled(SwiperSlide)`
    position: relative;
    max-width: 100vw;
    display: flex;
    justify-content: center;
    background: none;
    border: none;
`

export const StyleSlideHidder = styled.div`
    width: 100vw;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: relative;
`

export const StyledContainerDiv = styled.div`
    position: absolute;
    pointer-events: none;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
`

export const StyledContentDivTop = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    color: ${props => props.theme.textLight};
    padding: 0 0 4rem 0;
    width: 100%;
    z-index: 50;
`



export default function HorizontalAspectRatioVideoSlide(props) {
    const [video, setVideo] = useState();
    function resetVideoSeek(){
        if (video) {
            // console.log("Reset video:"+props.source)
            video.seekTo(0);
        }
    }

    useEffect(() => {
        if(props.isActive) {
            resetVideoSeek(video);
        }
      }, [props.isActive]);

    return (
        <StyleSlideHidder>
            <MinimalVideo className="slider-bg" looping={true} source={props.source} onReady={setVideo} aspectRatio={props.aspectRatio} playing={props.playing} previewImage={props.previewImage}/>
            <StyledContainerDiv className="content">
                <StyledContentDivTop>
                    {props.children}
                </StyledContentDivTop>
            </StyledContainerDiv>
        </StyleSlideHidder>
    );
}
