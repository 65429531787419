import styled, { css } from "styled-components";
import React, {useState, useEffect, useRef} from 'react'
import { device } from '../models/device';
import { useSpring, animated } from 'react-spring';
import { Fragment } from "react";
import ReactPlayer from 'react-player/lazy'
import { useInView } from 'react-intersection-observer';
import { useMemo } from "react";

const VideoPlayer= styled(ReactPlayer).attrs( props => ({
    style: {
        // animationName: props.animationName,
        // animationDelay:  0
    }
}))`

    border-radius: ${props => props.borderRadius};

    filter: brightness(${props => props.videoBrightness ? props.videoBrightness + '%' : '100%'});
    aspect-ratio: ${props => props.aspectRatio ? props.aspectRatio : 'unset'};
    pointer-events: none;
    object-position: center center;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    /* hack to make this work */
    * {
        border-radius: ${props => props.borderRadius};
        filter: brightness(${props => props.videoBrightness ? props.videoBrightness + '%' : '100%'});
        aspect-ratio: ${props => props.aspectRatio ? props.aspectRatio : 'unset'};
        object-fit:cover;
        pointer-events: none;
    }
    /* opacity: 0; */
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
`

const PlaceholderDiv = styled.div`
    border-radius: ${props => props.borderRadius};
    aspect-ratio: ${props => props.aspectRatio ? props.aspectRatio : 'unset'};
    pointer-events: none;
    object-position: center center;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
`

const PlaceholderImg = styled.img.attrs( props => ({
    style: {
        animationName: props.animationName,
        animationDelay:  0
    }
}))`
    border-radius: ${props => props.borderRadius};
    aspect-ratio: ${props => props.aspectRatio ? props.aspectRatio : 'unset'};
    pointer-events: none;
    object-position: center center;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    filter: brightness(${props => props.imgBrightness ? props.imgBrightness + '%' : '100%'});
    opacity: 1;

    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-direction: reverse;
`

const ContainerDiv = styled.div`
    border-radius: ${props => props.borderRadius};
    aspect-ratio: ${props => props.aspectRatio ? props.aspectRatio : 'unset'};
    pointer-events: none;
    object-position: center center;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;

    position: relative;
    overflow: hidden;
    background: none;
    /* background: red; */
`

const PreviewImage = styled.img.attrs( props => ({
    style: {
        animationName: props.animationName,
        animationDelay:  0
    }
}))`
    top: 0;
    left: 0;
    border: none;
    outline: none;
    object-position: center center;
    object-fit: cover;
    filter: brightness(${props => props.imgBrightness ? props.imgBrightness + '%' : '100%'});
    position: absolute;
    aspect-ratio: ${props => props.aspectRatio ? props.aspectRatio : 'unset'};
    width: 100%;
    height: 100%;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-direction: reverse;
    pointer-events: none;
    border-radius: ${props => props.borderRadius};
    opacity: 1;
`

export default function MinimalVideo(props) {
    const { ref, inView, entry } = useInView({
        threshold: 0,
      });

      
    useEffect(() => {
        if(videoRef){
            videoRef.current.seekTo(0);
        }
        setAnimation('unset');
        if(animation != 'fadeAndBlurIn' && props.playing && inView){
            console.log('Fade in starting for video:'+props.source); 
            setAnimation('fadeAndBlurIn');
         }else if (!inView) {
            setAnimation('unset');
         }
      }, [inView]);

      useEffect(() => {
        console.log(`playing state set on video ${props.source} to ${props.playing}`);
        if(animation != 'fadeAndBlurIn' && props.playing){
           console.log('Fade in starting for video:'+props.source); 
           setAnimation('fadeAndBlurIn');
        }
      }, [props.playing]);

    const videoRef = useRef(null);
    const [animation, setAnimation] = useState('unset');
    return(
        <ContainerDiv ref={ref} borderRadius={props.borderRadius} aspectRatio={props.aspectRatio}>
            {
            <VideoPlayer ref={videoRef} borderRadius={props.borderRadius ? props.borderRadius : '0'} animationName={animation} url={props.source} playing={props.playing} controls={false} width='100%' height='100%' muted={true} loop={props.looping} onEnded={props.onEnded} aspectRatio={props.aspectRatio} videoBrightness={props.videoBrightness}
            config={{
                file: {
                attributes: {
                    controlsList: "nofullscreen",
                    playsInline: true,
                },
                },
            }}
            onReady={() => {
                if(animation != 'fadeAndBlurIn' && props.playing){
                    if(props.onReady) {
                        props.onReady(videoRef.current);
                    }
                    console.log('Fade in starting for video:'+props.source); 
                    setAnimation('fadeAndBlurIn');
                }
                }}/>

            }
            <PreviewImage borderRadius={props.borderRadius ? props.borderRadius : '0'} aspectRatio={props.aspectRatio} imgBrightness={props.videoBrightness} animationName={animation} src={props.previewImage}/>
        </ContainerDiv>
    )
}